var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{staticClass:"topics-condition-view",attrs:{"slim":""}},[_c('black-white-list-input',{attrs:{"setup":{
      'func': _vm.newConfigInputSetup,
      'args': {
        'model': this,
        'key': 'topicsInput',
        'validation': 'required',
        'options': _vm.chatTopicsOptions,
        'availableTabs': [_vm.ChipsInputTabs.Chips]
      }
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }